<template>
  <div class="container">
    <Header active="data" />
    <div class="main">
      <el-carousel class="banner" @change="bannerChange" interval="6000">
        <el-carousel-item>
          <div class="item">
            <transition
              enter-active-class="animate__animated animate__fadeIn cus-animated"
              leave-active-class="animate__animated animate__fadeOut"
            >
              <div v-if="b1show" class="text">
                <div class="title">公开数据实时采集</div>
                <div class="desc">
                  实时对全球超10000+的网站更新的实时数据进行采集，并使用AI模型进行分析，对有价值的信息进行提取并保存入库，为后续服务提供数据支持
                </div>
              </div>
            </transition>
            <img
              class="img"
              :src="require('../../../assets/images/website/data.jpg')"
            />
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="data">
        <el-tabs type="border-card">
          <el-tab-pane label="国民经济和社会发展统计公报">
            <div class="sift">
              <el-row class="region">
                <el-col :span="2" class="tle">选择省份：</el-col>
                <el-col :span="22" class="items">
                  <span class="item"
                    ><a href="javascript:void" @click="regionSearch('', '')"
                      >全部省份</a
                    ></span
                  >
                  <span
                    class="item"
                    v-for="region in regions"
                    :key="region.code"
                    ><a
                      href="javascript:void"
                      @click="regionSearch(region.code, region.name)"
                      >{{ region.name }}</a
                    ></span
                  >
                </el-col>
              </el-row>
              <el-row class="years">
                <el-col :span="2" class="tle">选择年份：</el-col>
                <el-col :span="22" class="items">
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch('')"
                      >全部年限</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2022)"
                      >2022年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2021)"
                      >2021年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2020)"
                      >2020年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2019)"
                      >2019年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2018)"
                      >2018年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2017)"
                      >2017年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2016)"
                      >2016年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2015)"
                      >2015年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2014)"
                      >2014年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2013)"
                      >2013年</a
                    ></span
                  >
                </el-col>
              </el-row>
            </div>
            <div class="content">
              <el-row :gutter="20">
                <el-col
                  :span="8"
                  v-for="develop in develops"
                  :key="develop.code"
                >
                  <div class="item">
                    <div class="top">
                      <div class="tle">
                        <span class="fc-cyan">九维</span>&nbsp;<span
                          class="fc-orange"
                          >数据</span
                        >
                      </div>
                      <div class="name">{{ develop.name }}</div>
                    </div>
                    <div class="name btd text-short">{{ develop.name }}</div>
                    <el-row class="btd">
                      <el-col :span="12" class="region text-short"
                        >{{ regionName }}-{{ develop.regionName }}</el-col
                      >
                      <el-col :span="12" class="years"
                        >{{ develop.years }}年</el-col
                      >
                    </el-row>
                    <el-row class="btn btd">
                      <el-col :span="24"
                        ><el-button
                          type="primary"
                          icon="el-icon-s-promotion"
                          size="mini"
                          @click="serviceApply(develop.code)"
                          >发送到邮箱</el-button
                        ></el-col
                      >
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
            <Pagination :callback="developCallback" ref="developPage" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog title="数据下载申请" v-model="applyState">
      <el-form :model="ruleForm" label-width="80px" class="form">
        <el-form-item label="姓名">
          <el-input
            v-model="ruleForm.name"
            placeholder="请填写您的姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input
            v-model="ruleForm.phone"
            placeholder="请填写您的联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input
            v-model="ruleForm.email"
            placeholder="请填写您的电子邮箱"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="applyState = false">取消</el-button>
          <el-button type="primary" @click="serviceApplySave"> 确认 </el-button>
        </span>
      </template>
    </el-dialog>
    <Footer />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="scss" scoped>
@import './Index.scss';
</style>
