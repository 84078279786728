import 'animate.css'
import '../../../assets/icon/font-icon.js'
import Header from '../common/Header.vue'
import Footer from '../common/Footer.vue'
import Pagination from '../../../components/Pagination.vue'
import { ElCarousel, ElCarouselItem, ElTabs, ElTabPane, ElDialog, ElSelect, ElOption, ElForm, ElFormItem, ElInput } from 'element-plus'
export default {
    components: {
        Header: Header,
        Footer: Footer,
        Pagination: Pagination,
        [ElCarousel.name]: ElCarousel,
        [ElCarouselItem.name]: ElCarouselItem,
        [ElTabs.name]: ElTabs,
        [ElTabPane.name]: ElTabPane,
        [ElDialog.name]: ElDialog,
        [ElSelect.name]: ElSelect,
        [ElOption.name]: ElOption,
        [ElForm.name]: ElForm,
        [ElFormItem.name]: ElFormItem,
        [ElInput.name]: ElInput
    },
    data () {
        return {
            applyState: false,
            b1show: false,
            developCode: '',
            regionCode: '610000',
            regionName: '陕西省',
            years: '',
            develops: [],
            regions: [
                { code: '110000', name: '北京市' },
                { code: '120000', name: '天津市' },
                { code: '130000', name: '河北省' },
                { code: '140000', name: '山西省' },
                { code: '210000', name: '辽宁省' },
                { code: '220000', name: '吉林省' },
                { code: '230000', name: '黑龙江省' },
                { code: '310000', name: '上海市' },
                { code: '320000', name: '江苏省' },
                { code: '330000', name: '浙江省' },
                { code: '340000', name: '安徽省' },
                { code: '350000', name: '福建省' },
                { code: '360000', name: '江西省' },
                { code: '370000', name: '山东省' },
                { code: '410000', name: '河南省' },
                { code: '420000', name: '湖北省' },
                { code: '430000', name: '湖南省' },
                { code: '440000', name: '广东省' },
                { code: '450000', name: '广西壮族自治区' },
                { code: '460000', name: '海南省' },
                { code: '500000', name: '重庆市' },
                { code: '510000', name: '四川省' },
                { code: '520000', name: '贵州省' },
                { code: '530000', name: '云南省' },
                { code: '540000', name: '西藏自治区' },
                { code: '610000', name: '陕西省' },
                { code: '620000', name: '甘肃省' },
                { code: '630000', name: '青海省' },
                { code: '640000', name: '宁夏回族自治区' },
                { code: '650000', name: '新疆维吾尔自治区' }
            ],
            ruleForm: {
                name: '',
                phone: '',
                email: ''
            }
        }
    },
    mounted () {
        this.color_m = window.const.global.HRP_COLOR_M
        this.color_s1 = window.const.global.HRP_COLOR_S1
        this.search()
        this.b1show = true
    },
    methods: {
        bannerChange (val) {
            if (val === 0) {
                this.b1show = true
                this.b2show = false
            } else {
                this.b1show = false
                this.b2show = true
            }
        },
        developCallback (data) {
            this.develops = data
        },
        regionSearch (regionCode, regionName) {
            this.regionCode = regionCode
            this.regionName = regionName
            this.search()
        },
        yearsSearch (years) {
            this.years = years
            this.search()
        },
        serviceApply (code) {
            this.applyState = true
            this.developCode = code
        },
        async serviceApplySave () {
            if (this.ruleForm.name === '') {
                this.$alert('请填写姓名', '提示信息')
            } else if (this.ruleForm.phone === '') {
                this.$alert('请填写手机号码', '提示信息')
            } else {
                var content = { name: this.ruleForm.name, phone: this.ruleForm.phone, email: this.ruleForm.email }
                var param = { type: 'DVP', code: this.developCode }
                var pd = { app: 'PCW_TTP', label: 'TTP_DATA_SEND', param: JSON.stringify(param), content: JSON.stringify(content) }
                const { data: res } = await this.$http.post(this.BMS_URL + '/call/message/createMessage', this.$qs.stringify(pd))
                if (res.status === '200') {
                    this.$alert('提交成功请耐心等待', '提示信息').then(() => {
                        this.applyState = false
                    })
                } else {
                    this.$alert('提交失败，请联系在线客服', '提示信息').then(() => {
                        this.applyState = false
                    })
                }
            }
        },
        async search () {
            var pd = { regionCode: this.regionCode, years: this.years }
            this.$refs.developPage.cusSearchPage(this.BMS_URL + '/stats/developAPC/retrieveDevelopPage', pd, { size: 15 })
        }
    }
}
